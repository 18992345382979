/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-destructuring */
<template>
  <b-overlay
    :show="loading"
    opacity="0.3"
    variant="transparent"
    spinner-variant="primary"
    spinner-type="border"
    rounded="sm"
    style="min-height: 200px;"
  >
    <div v-if="email">
      <b-card>
        <b-card-title>
          <p class="d-inline-block">
            <b>
              Email: #{{ email._id }}
            </b>
          </p>
        </b-card-title>
        <b-card-text>
          <h4>Email Body:</h4>
          <p
            v-if="email && email.body && email.body.textHtml"
            v-safe-html="email.body.textHtml"
          />
          <p
            v-else-if="email && email.body && email.body.textAsHtml"
            v-safe-html="email.body.textAsHtml"
          />
          <p
            v-else-if="email && email.body && email.body.text"
            v-safe-html="email.body.text"
          />
        </b-card-text>
      </b-card>

      <b-card>
        <b-card-title>
          <p><b>Attachments:</b></p>
        </b-card-title>
        <b-card-text>
          <div
            v-for="attachment in email.attachments"
            :key="attachment.name"
          >
            <a
              :href="attachment.url"
              target="_blank"
            >{{ attachment.name }}</a>
          </div>

          <div
            v-if="email.attachments && !email.attachments.length"
            class="d-flex"
          >
            <feather-icon
              class="text-danger d-inline-block"
              icon="PaperclipIcon"
              size="20"
            />
            <p
              class="d-inline-block ml-1"
            >
              No attachments found.
            </p>
          </div>
        </b-card-text>
      </b-card>

      <div class="row">
        <div class="col-12 col-lg-6 col-xl-4">
          <b-card>
            <b-card-title>
              <b-button
                class="pl-0 btn-title text-left"
                size="lg"
                variant="link"
                @click="toggleTripDetails"
              >
                <feather-icon
                  class="text-ligth"
                  :icon="showTripDetails ? 'ChevronDownIcon' : 'ChevronRightIcon'"
                  size="22"
                />
                <span>
                  <b>Trip:</b>
                  <router-link
                    v-if="email.trip && email.trip._id"
                    class="ml-2"
                    :to="{ name: 'editTrip', params: { tripId: email.trip._id }}"
                  >
                    {{ email.trip._id }}
                  </router-link>
                </span>
              </b-button>
            </b-card-title>
            <b-card-text v-show="showTripDetails">
              <div
                v-for="(value, propertyName) in email.trip"
                :key="propertyName"
                class=""
              >
                <div v-if="propertyName !== '__v'">
                  <p>
                    {{ propertyName }} :
                    <b>
                      <span v-safe-html="value" />
                    </b>
                  </p>
                </div>
              </div>
            </b-card-text>
          </b-card>
        </div>

        <div class="col-12 col-lg-6 col-xl-4">
          <b-card>
            <b-card-title>
              <b-button
                class="pl-0 btn-title text-left"
                size="lg"
                variant="link"
                @click="toggleVendorDetails"
              >
                <feather-icon
                  class="text-ligth"
                  :icon="showVendorDetails ? 'ChevronDownIcon' : 'ChevronRightIcon'"
                  size="22"
                />
                <span>
                  <b>Vendor:</b>
                  <router-link
                    v-if="email.vendor && email.vendor._id"
                    class="ml-2"
                    :to="{ name: 'editVendor', params: { vendorId: email.vendor._id }}"
                  >
                    {{ email.vendor._id }}
                  </router-link>
                </span>
              </b-button>
            </b-card-title>
            <b-card-text v-show="showVendorDetails">
              <div
                v-for="(value, propertyName) in email.vendor"
                :key="propertyName"
                class=""
              >
                <div v-if="propertyName !== '__v'">
                  <p>
                    {{ propertyName }} :
                    <b>
                      <span v-safe-html="value" />
                    </b>
                  </p>
                </div>
              </div>
            </b-card-text>
          </b-card>
        </div>

        <div class="col-12 col-lg-12 col-xl-4">
          <b-card>
            <b-card-title>
              <b-button
                class="pl-0 btn-title text-left"
                size="lg"
                variant="link"
                @click="toggleDomainDetails"
              >
                <feather-icon
                  class="text-ligth"
                  :icon="showDomainDetails ? 'ChevronDownIcon' : 'ChevronRightIcon'"
                  size="22"
                />
                <span>
                  <b>Domain:</b>
                  <router-link
                    v-if="email.domain && email.domain._id"
                    class="ml-2"
                    :to="{ name: 'editDomain', params: { domainId: email.domain._id }}"
                  >
                    {{ email.domain._id }}
                  </router-link>
                </span>
              </b-button>
            </b-card-title>
            <b-card-text v-show="showDomainDetails">
              <div
                v-for="(value, propertyName) in email.domain"
                :key="propertyName"
                class=""
              >
                <div v-if="propertyName !== '__v' && propertyName != 'smtp' && propertyName !== 'imap'">
                  <p>
                    {{ propertyName }} :
                    <b>
                      <span v-safe-html="value" />
                    </b>
                  </p>
                </div>
              </div>
            </b-card-text>
          </b-card>
        </div>
      </div>

      <b-card>
        <b-form
          v-if="email"
          @submit.prevent="submitForm"
        >
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="from"
                label-for="h-from"
                label-cols-md="4"
              >
                <b-form-input
                  id="h-from"
                  v-model="email.from"
                  type="email"
                  placeholder="From"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="To"
                label-for="h-to"
                label-cols-md="4"
              >
                <b-form-input
                  id="h-to"
                  v-model="email.to"
                  type="email"
                  placeholder="To"
                />
              </b-form-group>
            </b-col>

            <!-- submit and reset -->
            <b-col offset-md="4">
              <b-button
                type="submit"
                variant="primary"
                class="mr-1"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </div>
  </b-overlay>
</template>

<script>
/* eslint-disable */

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BCard,
  BCardTitle,
  BCardText,
  BOverlay
} from 'bootstrap-vue'

export default {
  name: 'EditEmail',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    BCardTitle,
    BCardText,
    BOverlay,
  },
  props: {
    emailId: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      email: '',
      loading: false,
      showTripDetails: false,
      showVendorDetails: false,
      showDomainDetails: false
    }
  },
  beforeMount() {
    this.loadEmail()
  },
  methods: {
    toggleTripDetails() {
      this.showTripDetails = !this.showTripDetails
    },
    toggleVendorDetails() {
      this.showVendorDetails = !this.showVendorDetails
    },
    toggleDomainDetails() {
      this.showDomainDetails = !this.showDomainDetails
    },
    async loadEmail() {
      const self = this

      self.loading = true
      await this.$store.dispatch('app/fetchEmail', self.emailId)
        .then(() => {
          self.email = self.$store.state.app.email
        })
        .catch(() => {
        })
        .finally(() => {
          self.loading = false
        })
    },
    submitForm() {
      const self = this

      this.$store.dispatch('app/saveEmail', this.email).catch().then(() => {
        self.$router.push('/emails')
      })
    }
  },
}
</script>
